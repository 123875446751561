import { graphql, Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import { BlogPostPreview } from "../components/post-preview"
import SEO from "../components/seo"
import { HbrPageQuery } from "../graphqlTypes"

const HbrPage: React.FC<{ data: HbrPageQuery }> = ({ data }) => {
  return (
    <Layout>
      <SEO title="ludusrusso" />
      <Hero />
      <div className="max-w-6xl m-auto">
        <ul className="mx-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {data.allMdx.nodes.map(post => {
            return <BlogPostPreview key={post.id} post={post} />
          })}
        </ul>
      </div>
    </Layout>
  )
}

export default HbrPage

export const query = graphql`
  query HbrPage {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { path: { regex: "$/hbr/" } } }
    ) {
      nodes {
        ...PostPreview
      }
    }
  }
`

const P = ({ children }) => {
  return <p className="text-xl mt-5">{children}</p>
}

const A = ({ children, href }) => (
  <OutboundLink
    href={href}
    className="border-b-2 border-green-500 text-green-900 font-semibold hover:bg-green-200"
  >
    {children}
  </OutboundLink>
)

const Hero = () => {
  const emojis = ["🖖", "🤙", "🛩", "🚀"]

  return (
    <div>
      <div className="max-w-xl mx-auto text-gray-900">
        <h1 className="text-4xl text-center mt-10 font-bold">
          I am <span className="text-green-600"> Ludovico </span> 🛩
        </h1>

        <P>
          I'm a cloud architect{" "}
          <span role="img" aria-label="cloud">
            ☁️
          </span>{" "}
          and a full stack developer{" "}
          <span role="img" aria-label="rocket">
            🚀
          </span>{" "}
          independent consultant based in Italy (Milano) with a background in
          Cloud Robotics{" "}
          <span role="img" aria-label="robot">
            🤖
          </span>{" "}
          and Computer Vision{" "}
          <span role="img" aria-label="robot">
            📷
          </span>
          !
        </P>

        <div className="w-full my-10">
          <DevImage />
        </div>

        <P>
          I'm passionate on learning new techs, mainly on web and cloud
          computing, making and robotics!
        </P>

        <h2 className="text-4xl text-center mt-20 font-bold">
          I like to <span className="text-green-600">share</span>
          <span role="img" aria-label="share">
            🤝
          </span>
        </h2>

        <P>
          I'm a co-organizer of{" "}
          <A href="https://www.youtube.com/channel/UCs2Lulo9cfYrI5RHuDCQehQ">
            GDG Cloud Milano
          </A>{" "}
          and I like to speak at tech events and I'm a co-founder of{" "}
          <A href="http://www.hackability.it">Hackability</A>, an Italian non
          profit organization that help designers, makers and people with
          disability to work together on Open Source and Open Hardware assistive
          technologies!
        </P>

        <div className="mt-10 max-w-md m-auto shadow">
          <StaticImage src="../images/mentoring.jpg" alt="Mentoring" />
          <div className="p-4">
            <h3 className="font-semibold text-xl">
              I offer <span className="text-green-600">Free mentoring</span>
            </h3>
            <p className="text-grey-800 mt-1">
              I offer free one-2-one mentoring sessions on my main interest!
            </p>

            <p className="mt-1">
              <Link className="font-bold text-green-800" to="/mentoring">
                Learn more...
              </Link>
            </p>

            <OutboundLink
              className="inline-block mt-4 bg-green-600 px-4 py-2 rounded text-white font-bold hover:bg-green-800"
              href="https://calendly.com/ludusrusso/one2one"
              target="_blank"
              rel="noreferrer"
            >
              Schedule a session
            </OutboundLink>
          </div>
        </div>
      </div>
      <div className="mb-10">
        <h2 className="text-4xl text-center mt-20 font-bold">
          I have a <span className="text-green-600">blog</span>
          <span role="img" aria-label="blog">
            {" "}
            📖
          </span>
        </h2>
      </div>
    </div>
  )
}

const DevImage = () => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1144 617.3"
  >
    <defs />
    <path
      d="M1144 396.2a182 182 0 01-6.8 49.6 180 180 0 01-19.3 44.3c0 50.1-33.8 93.4-83 114a167.3 167.3 0 01-64.7 12.8h-753A209 209 0 01109 587.5a185.8 185.8 0 01-24.8-17.8c-34-29.3-55-69.6-55-114.2A180.5 180.5 0 010 356.7c0-73.3 43.4-136.4 105.5-164.2a174.5 174.5 0 0125.6-9.2q4.6-1.2 9.3-2.2a175.4 175.4 0 0125.5-3.5l9.4-.4h1.7c3.5 0 6.9.2 10.3.4l1.1-2q2-3.4 4.2-6.7 4-6.2 8.2-12.2 4.5-6.2 9.4-12.3l.8-1 5.6-6.8q9-10.5 19-20.4C307.7 45.5 420.7 0 547.7 0 646.5 0 737 27.6 806.1 73.1A174.2 174.2 0 01892 50.5c97.7 0 177 80.4 177 179.5a184.9 184.9 0 01-1 18.8 180 180 0 0176 147.4z"
      fill="#63ff64"
      opacity=".1"
    />
    <path
      d="M235.7 116.2v20.3h-19q8.9-10.5 19-20.3zM245 111h25.5v25.5H245zM279.9 111h25.5v25.5h-25.5zM314.8 111h25.5v25.5h-25.5zM349.6 111h25.5v25.5h-25.5zM384.5 111H410v25.5h-25.5z"
      fill="#63ff64"
      opacity=".2"
    />
    <path fill="#63ff64" d="M419.4 111h25.5v25.5h-25.5z" />
    <path
      fill="#63ff64"
      opacity=".2"
      d="M454.2 111h25.5v25.5h-25.5zM489.1 111h25.5v25.5h-25.5zM523.9 111h25.5v25.5h-25.5z"
    />
    <path fill="#63ff64" d="M558.8 111h25.5v25.5h-25.5z" />
    <path
      fill="#63ff64"
      opacity=".2"
      d="M593.7 111h25.5v25.5h-25.5zM628.5 111H654v25.5h-25.5zM663.4 111h25.5v25.5h-25.5zM698.3 111h25.5v25.5h-25.5zM733.1 111h25.5v25.5h-25.5zM768 111h25.5v25.5H768z"
    />
    <path
      fill="#63ff64"
      d="M802.9 111h25.5v25.5h-25.5zM837.7 111h25.5v25.5h-25.5z"
    />
    <path
      fill="#63ff64"
      opacity=".2"
      d="M872.6 111h25.5v25.5h-25.5zM907.5 111H933v25.5h-25.5z"
    />
    <path
      fill="#63ff64"
      d="M942.3 111h25.5v25.5h-25.5zM977.2 111h25.5v25.5h-25.5zM200.8 156.7v12.1h-8.2q4-6.1 8.2-12z"
    />
    <path fill="#63ff64" opacity=".2" d="M210.2 143.3h25.5v25.5h-25.5z" />
    <path fill="#63ff64" d="M245 143.3h25.5v25.5H245z" />
    <path fill="#63ff64" opacity=".2" d="M279.9 143.3h25.5v25.5h-25.5z" />
    <path fill="#63ff64" opacity=".7" d="M314.8 143.3h25.5v25.5h-25.5z" />
    <path fill="#63ff64" opacity=".2" d="M349.6 143.3h25.5v25.5h-25.5z" />
    <path fill="#63ff64" opacity=".4" d="M384.5 143.3H410v25.5h-25.5z" />
    <path fill="#63ff64" d="M419.4 143.3h25.5v25.5h-25.5z" />
    <path fill="#63ff64" opacity=".4" d="M454.2 143.3h25.5v25.5h-25.5z" />
    <path fill="#63ff64" opacity=".2" d="M489.1 143.3h25.5v25.5h-25.5z" />
    <path fill="#63ff64" opacity=".4" d="M523.9 143.3h25.5v25.5h-25.5z" />
    <path fill="#63ff64" d="M558.8 143.3h25.5v25.5h-25.5z" />
    <path
      fill="#63ff64"
      opacity=".4"
      d="M593.7 143.3h25.5v25.5h-25.5zM628.5 143.3H654v25.5h-25.5z"
    />
    <path fill="#63ff64" opacity=".2" d="M663.4 143.3h25.5v25.5h-25.5z" />
    <path
      fill="#63ff64"
      opacity=".4"
      d="M698.3 143.3h25.5v25.5h-25.5zM733.1 143.3h25.5v25.5h-25.5z"
    />
    <path
      fill="#63ff64"
      opacity=".2"
      d="M768 143.3h25.5v25.5H768zM802.9 143.3h25.5v25.5h-25.5z"
    />
    <path fill="#63ff64" opacity=".4" d="M837.7 143.3h25.5v25.5h-25.5z" />
    <path fill="#63ff64" opacity=".2" d="M872.6 143.3h25.5v25.5h-25.5z" />
    <path
      fill="#63ff64"
      d="M907.5 143.3H933v25.5h-25.5zM942.3 143.3h25.5v25.5h-25.5zM977.2 143.3h25.5v25.5h-25.5z"
    />
    <path
      d="M131 183.3v17.9h-25.4v-8.7a174.5 174.5 0 0125.5-9.2zM165.9 177.6v23.6h-25.5v-20.1l25.5-3.5z"
      fill="#63ff64"
      opacity=".2"
    />
    <path fill="#63ff64" d="M175.3 175.7h25.5v25.5h-25.5z" />
    <path fill="#63ff64" opacity=".7" d="M210.2 175.7h25.5v25.5h-25.5z" />
    <path fill="#63ff64" d="M245 175.7h25.5v25.5H245z" />
    <path
      fill="#63ff64"
      opacity=".7"
      d="M279.9 175.7h25.5v25.5h-25.5zM314.8 175.7h25.5v25.5h-25.5z"
    />
    <path fill="#63ff64" opacity=".2" d="M349.6 175.7h25.5v25.5h-25.5z" />
    <path
      fill="#63ff64"
      opacity=".4"
      d="M384.5 175.7H410v25.5h-25.5zM419.4 175.7h25.5v25.5h-25.5z"
    />
    <path fill="#63ff64" d="M454.2 175.7h25.5v25.5h-25.5z" />
    <path fill="#63ff64" opacity=".2" d="M489.1 175.7h25.5v25.5h-25.5z" />
    <path fill="#63ff64" opacity=".4" d="M523.9 175.7h25.5v25.5h-25.5z" />
    <path fill="#63ff64" d="M558.8 175.7h25.5v25.5h-25.5z" />
    <path fill="#63ff64" opacity=".2" d="M593.7 175.7h25.5v25.5h-25.5z" />
    <path fill="#63ff64" opacity=".4" d="M628.5 175.7H654v25.5h-25.5z" />
    <path fill="#63ff64" opacity=".2" d="M663.4 175.7h25.5v25.5h-25.5z" />
    <path fill="#63ff64" d="M698.3 175.7h25.5v25.5h-25.5z" />
    <path fill="#63ff64" opacity=".2" d="M733.1 175.7h25.5v25.5h-25.5z" />
    <path
      fill="#63ff64"
      opacity=".7"
      d="M768 175.7h25.5v25.5H768zM802.9 175.7h25.5v25.5h-25.5z"
    />
    <path fill="#63ff64" opacity=".4" d="M837.7 175.7h25.5v25.5h-25.5z" />
    <path fill="#63ff64" opacity=".2" d="M872.6 175.7h25.5v25.5h-25.5z" />
    <path
      fill="#63ff64"
      d="M907.5 175.7H933v25.5h-25.5zM942.3 175.7h25.5v25.5h-25.5zM977.2 175.7h25.5v25.5h-25.5z"
    />
    <path
      fill="#63ff64"
      opacity=".2"
      d="M105.5 208H131v25.5h-25.5zM140.4 208h25.5v25.5h-25.5zM175.3 208h25.5v25.5h-25.5zM210.2 208h25.5v25.5h-25.5z"
    />
    <path fill="#63ff64" d="M245 208h25.5v25.5H245z" />
    <path
      fill="#63ff64"
      opacity=".7"
      d="M279.9 208h25.5v25.5h-25.5zM314.8 208h25.5v25.5h-25.5z"
    />
    <path
      fill="#63ff64"
      opacity=".2"
      d="M349.6 208h25.5v25.5h-25.5zM384.5 208H410v25.5h-25.5z"
    />
    <path
      fill="#63ff64"
      opacity=".4"
      d="M419.4 208h25.5v25.5h-25.5zM454.2 208h25.5v25.5h-25.5z"
    />
    <path fill="#63ff64" d="M489.1 208h25.5v25.5h-25.5z" />
    <path fill="#63ff64" opacity=".2" d="M523.9 208h25.5v25.5h-25.5z" />
    <path
      fill="#63ff64"
      opacity=".4"
      d="M558.8 208h25.5v25.5h-25.5zM593.7 208h25.5v25.5h-25.5zM628.5 208H654v25.5h-25.5zM663.4 208h25.5v25.5h-25.5z"
    />
    <path
      fill="#63ff64"
      opacity=".2"
      d="M698.3 208h25.5v25.5h-25.5zM733.1 208h25.5v25.5h-25.5zM768 208h25.5v25.5H768zM802.9 208h25.5v25.5h-25.5z"
    />
    <path
      fill="#63ff64"
      opacity=".7"
      d="M837.7 208h25.5v25.5h-25.5zM872.6 208h25.5v25.5h-25.5z"
    />
    <path fill="#63ff64" opacity=".4" d="M907.5 208H933v25.5h-25.5z" />
    <path fill="#63ff64" opacity=".2" d="M942.3 208h25.5v25.5h-25.5z" />
    <path fill="#63ff64" d="M977.2 208h25.5v25.5h-25.5z" />
    <path
      fill="#63ff64"
      opacity=".2"
      d="M105.5 240.3H131v25.5h-25.5zM140.4 240.3h25.5v25.5h-25.5z"
    />
    <path fill="#63ff64" opacity=".4" d="M175.3 240.3h25.5v25.5h-25.5z" />
    <path
      fill="#63ff64"
      opacity=".2"
      d="M210.2 240.3h25.5v25.5h-25.5zM245 240.3h25.5v25.5H245z"
    />
    <path
      fill="#63ff64"
      opacity=".7"
      d="M279.9 240.3h25.5v25.5h-25.5zM314.8 240.3h25.5v25.5h-25.5z"
    />
    <path
      fill="#63ff64"
      opacity=".2"
      d="M349.6 240.3h25.5v25.5h-25.5zM384.5 240.3H410v25.5h-25.5z"
    />
    <path fill="#63ff64" opacity=".4" d="M419.4 240.3h25.5v25.5h-25.5z" />
    <path fill="#63ff64" d="M454.2 240.3h25.5v25.5h-25.5z" />
    <path fill="#63ff64" opacity=".2" d="M489.1 240.3h25.5v25.5h-25.5z" />
    <path
      fill="#63ff64"
      d="M523.9 240.3h25.5v25.5h-25.5zM558.8 240.3h25.5v25.5h-25.5zM593.7 240.3h25.5v25.5h-25.5z"
    />
    <path fill="#63ff64" opacity=".2" d="M628.5 240.3H654v25.5h-25.5z" />
    <path fill="#63ff64" d="M663.4 240.3h25.5v25.5h-25.5z" />
    <path
      fill="#63ff64"
      opacity=".2"
      d="M698.3 240.3h25.5v25.5h-25.5zM733.1 240.3h25.5v25.5h-25.5zM768 240.3h25.5v25.5H768zM802.9 240.3h25.5v25.5h-25.5zM837.7 240.3h25.5v25.5h-25.5z"
    />
    <path fill="#63ff64" opacity=".7" d="M872.6 240.3h25.5v25.5h-25.5z" />
    <path
      fill="#63ff64"
      opacity=".2"
      d="M907.5 240.3H933v25.5h-25.5zM942.3 240.3h25.5v25.5h-25.5z"
    />
    <path fill="#63ff64" d="M977.2 240.3h25.5v25.5h-25.5z" />
    <path
      fill="#63ff64"
      opacity=".2"
      d="M105.5 272.6H131v25.5h-25.5zM140.4 272.6h25.5v25.5h-25.5zM175.3 272.6h25.5v25.5h-25.5z"
    />
    <path fill="#63ff64" opacity=".4" d="M210.2 272.6h25.5v25.5h-25.5z" />
    <path
      fill="#63ff64"
      opacity=".2"
      d="M245 272.6h25.5v25.5H245zM279.9 272.6h25.5v25.5h-25.5z"
    />
    <path fill="#63ff64" opacity=".7" d="M314.8 272.6h25.5v25.5h-25.5z" />
    <path
      fill="#63ff64"
      opacity=".2"
      d="M349.6 272.6h25.5v25.5h-25.5zM384.5 272.6H410v25.5h-25.5zM419.4 272.6h25.5v25.5h-25.5z"
    />
    <path fill="#63ff64" d="M454.2 272.6h25.5v25.5h-25.5z" />
    <path fill="#63ff64" opacity=".2" d="M489.1 272.6h25.5v25.5h-25.5z" />
    <path
      fill="#63ff64"
      d="M523.9 272.6h25.5v25.5h-25.5zM558.8 272.6h25.5v25.5h-25.5z"
    />
    <path
      fill="#63ff64"
      opacity=".2"
      d="M593.7 272.6h25.5v25.5h-25.5zM628.5 272.6H654v25.5h-25.5zM663.4 272.6h25.5v25.5h-25.5zM698.3 272.6h25.5v25.5h-25.5zM733.1 272.6h25.5v25.5h-25.5zM768 272.6h25.5v25.5H768zM802.9 272.6h25.5v25.5h-25.5zM837.7 272.6h25.5v25.5h-25.5zM872.6 272.6h25.5v25.5h-25.5zM907.5 272.6H933v25.5h-25.5z"
    />
    <path fill="#63ff64" opacity=".4" d="M942.3 272.6h25.5v25.5h-25.5z" />
    <path fill="#63ff64" d="M977.2 272.6h25.5v25.5h-25.5z" />
    <path
      fill="#63ff64"
      opacity=".7"
      d="M105.5 304.9H131v25.5h-25.5zM140.4 304.9h25.5v25.5h-25.5zM175.3 304.9h25.5v25.5h-25.5zM210.2 304.9h25.5v25.5h-25.5zM245 304.9h25.5v25.5H245z"
    />
    <path
      fill="#63ff64"
      opacity=".2"
      d="M279.9 304.9h25.5v25.5h-25.5zM314.8 304.9h25.5v25.5h-25.5zM349.6 304.9h25.5v25.5h-25.5zM384.5 304.9H410v25.5h-25.5zM419.4 304.9h25.5v25.5h-25.5zM454.2 304.9h25.5v25.5h-25.5z"
    />
    <path fill="#63ff64" d="M489.1 304.9h25.5v25.5h-25.5z" />
    <path
      fill="#63ff64"
      opacity=".2"
      d="M523.9 304.9h25.5v25.5h-25.5zM558.8 304.9h25.5v25.5h-25.5zM593.7 304.9h25.5v25.5h-25.5z"
    />
    <path fill="#63ff64" d="M628.5 304.9H654v25.5h-25.5z" />
    <path
      fill="#63ff64"
      opacity=".2"
      d="M663.4 304.9h25.5v25.5h-25.5zM698.3 304.9h25.5v25.5h-25.5zM733.1 304.9h25.5v25.5h-25.5zM768 304.9h25.5v25.5H768zM802.9 304.9h25.5v25.5h-25.5zM837.7 304.9h25.5v25.5h-25.5zM872.6 304.9h25.5v25.5h-25.5zM907.5 304.9H933v25.5h-25.5zM942.3 304.9h25.5v25.5h-25.5z"
    />
    <path fill="#63ff64" d="M977.2 304.9h25.5v25.5h-25.5z" />
    <circle cx="734.5" cy="363.9" r="34.9" fill="#767d9f" />
    <path
      d="M732.7 319.5q5.2 22.8 8.9 46c.7 4.5 1.4 9.3-.3 13.7-1.4 3.6-4.2 6.4-6.4 9.6s-4 7.2-2.6 10.8 5.3 5.6 9 6.6c10.8 3 22.3.8 33-2.3A53.3 53.3 0 00788 398c4.5-2.8 8.3-6.8 11.7-11 15.6-18.9 25.8-41.6 35.4-64.2a39.1 39.1 0 01-33-13.3c-4-4.7-7-11.1-4.7-16.8-6.5 3.6-14.2 3.8-21.2 6.3a75.5 75.5 0 00-12.5 6.1l-14.3 8.5c-5.4 3.2-10.5 6.1-16.8 5.8z"
      fill="#ae6e79"
    />
    <path
      d="M805.6 345.7c-6 11.9-14 23.3-25.4 30.4s-26.6 9-38 2c-5.6-3.4-9.8-8.6-14.7-13s-11-8.1-17.6-7.6c-7.2.7-12.9 6.3-17.7 11.7a139 139 0 00-19.7 26.6c-10 18.7-12.4 40.5-12 61.7s3.6 42.3 3.6 63.5c.1 26-4.3 52-8.7 77.6 41-5 82.2 6 123.4 10.2 26.4 2.8 53 2.7 79.4 2.6 4.7 0 9.6-.1 13.5-2.7a18.2 18.2 0 005.6-6.6 63.5 63.5 0 006.2-22c2.5-19.3 2.6-38.8 2.7-58.2.2-47 .3-94.1-5.6-140.8-1.5-11.4-3.4-23-7.8-33.7s-11.6-20.6-21.6-26.3c-6.4-3.7-21.4-10.2-29-6.5-8.5 4.2-12.5 23.1-16.6 31.1z"
      fill="#767d9f"
    />
    <path
      d="M808.7 478.4c-1.6-29-7.9-60 5.5-85.7 3.3-6.3-8.5-14.2-4.8-20.3a92 92 0 0010-24 18 18 0 00.7-8.8c-1.4-5.3-6.6-8.4-11-11.6-5-3.6-7.4-14.4-9-20.3s12.5 1.2 18.2-.9c8-2.9 18-1 25.9 1.9 4.4 1.6 9.1 1.7 13.8 1.8 9.1.3 18.3.5 27.3-.6 4-.5 8.1-1.3 12.2-1 10.4.5 19.5 7.6 25.6 16s10.1 18.3 15.2 27.4c4.2 7.6 9.2 14.8 12.8 22.7 3.2 7 5.2 14.5 7.2 22l5.1 19.2a25.6 25.6 0 011.3 8.5c-.3 3-1.7 6-3 8.7a164.1 164.1 0 01-18.7 28.5c-10.8 13.2-23.9 25.1-30.3 41-2.5 6.1-3.9 12.7-5.2 19.2a708 708 0 00-10.9 79.2 174.2 174.2 0 00-36.5-12.2 177.6 177.6 0 00-19-3c-5.6-.6-12.8.5-18.1-1-8.9-2.6-9.9-17.6-11.2-25.5a232.5 232.5 0 01-2.7-38c-.1-14.4.4-28.8-.4-43.2zM613.2 424.2c4.2 24 8.9 48 18 70.6l-.7.6-2.5 2c-2 1.5-4.3 2.7-6 4.6a8 8 0 00-1.5 2.4 5.5 5.5 0 000 4.5c-4.8 2.1-10.3 1.5-15.7 1.3-4.6-.2-9 0-12.7 2.5-5.2 3.5-6.7 10.3-7.5 16.5l-.8 6.8q-.4 3.1-.6 6.3a188.6 188.6 0 00-.1 21q0 2 .2 3.8c-16.4-.4-31.3-8.9-45.4-17.2a25 25 0 01-8.6-7 15.8 15.8 0 01-2.3-10.2c0-.6 0-1 .2-1.6a41.6 41.6 0 012.2-8.6 107.2 107.2 0 0119.2-32.7c3.6-4.2 7.6-8.3 9.3-13.5.7-2.3 1-4.7 1.8-7 3.3-9.9 15.3-14.6 19.4-24.1 1-2.3 1.4-4.8 2.7-7a17.6 17.6 0 014.2-4.8l2.2-1.8q10.6-8.2 20.4-17.4l3.6-3.5a59.5 59.5 0 001 13.5z"
      fill="#4c4981"
    />
    <path
      d="M714 353.2l-4.5 4.4-.4.4-1.7 1.5a39 39 0 00-7.5 8.8l-.2.2c-5 9.2-3.2 20.4-1.8 30.8a261.6 261.6 0 012.4 39c-.3 18-2.4 35.9-2.4 53.8a277.2 277.2 0 001.6 32.1l2.2 21.8a801.8 801.8 0 012.2 25.5l.5 10.2.1 6.5v3.6q0 3.6-.2 7l-.6 9c-.3 3.3-.8 6.9-3.1 9.2l-.5.4-3.2-.4a332.7 332.7 0 00-67.3-1.2 686 686 0 002-15l.6-5.5.6-6q.8-7.7 1.4-15.5.9-11.3 1.3-22.6l.3-10.8q.2-6.7.2-13.5l-.1-13c0-1.5 0-3-.2-4.4a27.4 27.4 0 00-.5-3.8 49.9 49.9 0 00-3-8.5l-.7-1.5-.3-.9c5.8-5 9.9-12 13.8-18.7 6.4-11 13-22.3 14-34.9s-5.2-26.7-17.2-30.5c-3.3-1-6.7-1.3-10.1-1.9-3.2-.5-9.8-1.8-13.5-4l-6 6a22.1 22.1 0 012.2-8.8l1.4.1a2.7 2.7 0 01-.2-1c0-1 .8-2.3 2.4-3.6 4.7-4 12-4.9 15-10.3 1.3-2.3 1.5-5 2.5-7.3 2.9-6.7 11.1-9.2 16-14.6s6.3-13 10.3-19a28.6 28.6 0 0112-10l1.2-.5a69.2 69.2 0 0126.8-4.4c3.3 0 12 .1 16.2 1.8a5.3 5.3 0 011.9 1.1c4.5 5-2.6 15.4-5.9 19z"
      fill="#4c4981"
    />
    <path
      d="M614.7 401.4a60.9 60.9 0 0146.8 4.8c7.2 4 13.6 9.6 17.6 16.8 6 10.8 5.7 23.8 5.3 36.1-.3 6.2-.5 12.6-3.2 18.1-3.2 6.5-9.1 10.9-15.2 14.5a42.2 42.2 0 01-12.3 5.3c-6.7 1.4-13.7.2-20.5-1-7.4-1.4-15.3-3-20.4-8.5-3-3.2-4.6-7.3-6.2-11.3l-7-17.5c-4-10.1-5.4-18.5 0-28.4 2.3-4.5 5.3-8.7 7.7-13.2 1.2-2.3 5.5-15.2 7.4-15.8z"
      fill="#4c4981"
    />
    <path
      d="M1035 574.2v30a167.3 167.3 0 01-64.7 12.7H700.6l-.5.4-3.2-.4H217.2A209 209 0 01109 587.6v-45.7l230.7-26.6h.2l134.4 11.4 34.8 3 18 1.5 37.4 3.2 19.2 1.6 28 2.4 4 .3 20.1 1.7 27.4 2.3 38.5 3.2 64.2 5.5 45.2 3.8 74.1 6.3 14.1 1.2 1.5.1 1.3.1 18.7 1.6 20.4 1.7 2.4.2z"
      fill="#504f60"
    />
    <path
      fill="#9c9ca1"
      d="M764 573.1l-289.1-49.3-11.3 56.7 132.6 15.8 40.8 5.1 112.3-22.1 14.7-6.2z"
    />
    <path
      d="M614.3 598.6l144.9-27.2a5 5 0 016 4.9 5 5 0 01-4.2 5l-141.4 26.4z"
      fill="#9c9ca1"
    />
    <path
      d="M677.2 513c4.5-.5 9-1.2 13.1-3.2 5-2.5 8.9-6.7 13.5-9.9 10.2-7 23.8-8 35.5-4s21.4 12.7 28.2 23c4.6 6.8 8 15.6 4.8 23.1a30.5 30.5 0 01-4.2 6.5l-8.3 10.9a29 29 0 01-5.7 6 24 24 0 01-10.8 3.8c-10.1 1.5-20.5.7-30.7 1.3-17.6 1.2-34.7 6.9-52.3 6.7-10.2-.1-20.1-2.2-30-4.2l-43.8-9c-7.6-1.6-16.2-3.8-19.8-10.8a25 25 0 01-2.1-10.2c-.5-9.6 0-20.3 6.7-27.3 6.2-6.5 16.2-8.6 23.7-13.4 9.1-5.9 12.2-4 21.9.5a120 120 0 0060.3 10.2z"
      fill="#ae6e79"
    />
    <path
      d="M677.2 513c4.5-.5 9-1.2 13.1-3.2 5-2.5 8.9-6.7 13.5-9.9 10.2-7 23.8-8 35.5-4s21.4 12.7 28.2 23c4.6 6.8 8 15.6 4.8 23.1a30.5 30.5 0 01-4.2 6.5l-8.3 10.9a29 29 0 01-5.7 6 24 24 0 01-10.8 3.8c-10.1 1.5-20.5.7-30.7 1.3-17.6 1.2-34.7 6.9-52.3 6.7-10.2-.1-20.1-2.2-30-4.2l-43.8-9c-7.6-1.6-16.2-3.8-19.8-10.8a25 25 0 01-2.1-10.2c-.5-9.6 0-20.3 6.7-27.3 6.2-6.5 16.2-8.6 23.7-13.4 9.1-5.9 12.2-4 21.9.5a120 120 0 0060.3 10.2z"
      opacity=".1"
    />
    <path
      d="M853 499.3a259.4 259.4 0 01-37.5 12.3c-21.6 4.8-44.2 4-65.6 9.4-11.3 3-22.5 7.6-34.1 6.6-5.2-.4-10.2-2-15.2-3.2-28.4-7-60.7-4.7-83.7 13.3a104.1 104.1 0 00-20.2 22 14.7 14.7 0 006 21.9A86.6 86.6 0 00631 589c13.1 1.2 26.3-.7 39.4-2.5a3346 3346 0 0058-8.6c7-1.1 14-2.3 21-3.2 50.2-6.3 101.3 1.4 151.7-3.9 5-.5 10.2-1.2 14.9-3.4 11.7-5.5 17.5-20 15-32.7s-11.7-23.4-23-29.9c-9.3-5.4-19.5-7.6-30-10-9.7-2.2-15.7.8-25 4.4z"
      fill="#ae6e79"
    />
    <path
      d="M960.7 423.1c5.5 7.3 6.7 17 6 26s-2.7 18.2-3 27.3c-.4 12.5 2.6 25 2 37.5-.3 8.7-2.4 17.3-4.8 25.7-2.9 9.8-7 20.5-16.2 25.2-12.1 6.3-37.5 2.8-50.3 7.7 1.2-7.2 12.3-17.7 13.4-25 .9-5 1.7-10 .8-15-1.1-6.4-4.8-12.1-9.3-16.8-7-7.5-16.3-13.2-26.5-15s-35-.4-43 6c-1-6.6 17.5-13 24-14.4 3.3-.7 7-.3 9.7-2.4 5-3.9 1.3-11.7 1.6-18 .2-5 3-9.6 3.7-14.6 1-8-4.2-16.1-2.2-24 .8-2.9 2.5-5.4 3.6-8.2 3.6-8.4 2-18 .1-27-1.2-5.7-2.3-12 .8-16.8 3-4.5 11.2 11.5 16.5 10.7 17.5-2.4 33.3-15.4 47.4-4.8 7.2 5.4 8.1 11 11 18.7 2.8 7.4 10 11.1 14.7 17.2z"
      opacity=".1"
    />
    <path
      d="M963.7 423.1c5.5 7.3 6.7 17 6 26s-2.7 18.2-3 27.3c-.4 12.5 2.6 25 2 37.5-.3 8.7-2.4 17.3-4.8 25.7-2.9 9.8-7 20.5-16.2 25.2-12.1 6.3-37.5 2.8-50.3 7.7 1.2-7.2 12.3-17.7 13.4-25 .9-5 1.7-10 .8-15-1.1-6.4-4.8-12.1-9.3-16.8-7-7.5-16.3-13.2-26.5-15s-35-.4-43 6c-1-6.6 17.5-13 24-14.4 3.3-.7 7-.3 9.7-2.4 5-3.9 1.3-11.7 1.6-18 .2-5 3-9.6 3.7-14.6 1-8-4.2-16.1-2.2-24 .8-2.9 2.5-5.4 3.6-8.2 3.6-8.4 2-18 .1-27-1.2-5.7-2.3-12 .8-16.8 3-4.5 8.6-6.2 14-7a68.4 68.4 0 0150 13c7.1 5.3 8 11 11 18.6 2.7 7.4 9.9 11.1 14.6 17.2z"
      fill="#4c4981"
    />
    <path
      d="M302 375.7l54.4 201a12.6 12.6 0 0010.9 9.2L612 609.4a12.5 12.5 0 0013.4-15.5l-59.6-242.4a12.5 12.5 0 00-13-9.5L313 360a12.5 12.5 0 00-11.1 15.7z"
      fill="#9c9ca1"
    />
    <ellipse cx="787.1" cy="387.7" rx="26.9" ry="27.3" fill="#fff" />
    <path
      d="M809.2 338s5.7 16.8-11 24l-2.2 3.7 10.5 10.3s12.9-23.6 11.5-32c0 0-2-8.4-8.8-6z"
      fill="#fff"
    />
    <circle cx="790.2" cy="389" r="25.9" fill="#63ff64" />
    <path
      d="M818.1 347.9l-4-6.8-6-.9c1.2-22.4-4.6-29-4.6-29l-1.4-.2-5.9-.5-11-11.5v-3.3c15.5 1.9 24.1 9.3 28.8 18 8.2 15.1 4.1 34.2 4.1 34.2z"
      fill="#63ff64"
    />
    <path
      d="M802.2 307v4l-5.9-.5-11-11.5s17 3.8 16.9 8zM818.1 347.9l-4-6.8v-27.4c8 15.1 4 34.2 4 34.2z"
      stroke="#4c4981"
      strokeMiterlimit="10"
      opacity=".1"
    />
    <ellipse
      cx="757.1"
      cy="518.2"
      rx="14.4"
      ry="26.3"
      transform="rotate(-30 479.4 499.8)"
      fill="#fff"
    />
    <path
      d="M733 374.7c7.3 12.5 16.2 21 9.3 25s-27 2-34.3-10.6-.7-30 6.2-34 11.5 7 18.8 19.6z"
      fill="#63ff64"
    />
    <path
      d="M797.7 284c0 40.2-27.5 79-67.7 79a72.8 72.8 0 010-145.7c40.2 0 67.7 26.4 67.7 66.7z"
      opacity=".1"
    />
    <circle cx="730" cy="288.1" r="72.8" fill="#ae6e79" />
    <path
      d="M723.6 255.9c8.6 3.2 13.8 12.1 21.6 17.1 11 7 25.3 5.2 37.8 1.7 7-1.9 14.4-4.2 21.2-2a6 6 0 003.3.4c1.5-.5 2.2-2.1 2.7-3.6a98.8 98.8 0 00-8.2-77.7c-3.3-6-7.3-11.9-12.7-16a53.3 53.3 0 00-14.6-7.4 145 145 0 00-28.8-7.2 18 18 0 00-5.3-.1c-2.4.4-4.6 1.7-6.7 2.8A68.8 68.8 0 01704 172c-14 .6-30-2.7-40.8 6.2-13 10.8-9.8 31.8-18.2 46.5-5.6 9.6-16.3 16.6-18 27.7a25.6 25.6 0 00.5 10 41 41 0 0038 30.4 14.4 14.4 0 007.8-1.6 17.6 17.6 0 004-3.9c11.5-13.5 24.6-39.4 46.3-31.4z"
      fill="#444053"
    />
    <path
      d="M810.2 262.1c-.5 1.5-1.2 3.2-2.7 3.6a6 6 0 01-3.3-.5c-6.9-2-14.2.3-21.2 2.2-12.5 3.4-26.9 5.3-37.8-1.8-7.8-5-13-14-21.6-17.1-21.7-8-34.8 17.9-46.2 31.4a17.6 17.6 0 01-4.1 4 14.4 14.4 0 01-7.8 1.5A41 41 0 01627 252v.3a25.6 25.6 0 00.6 10 41 41 0 0038 30.4 14.4 14.4 0 007.8-1.5 17.6 17.6 0 004-4c11.5-13.5 24.6-39.3 46.3-31.4 8.6 3.2 13.8 12.2 21.6 17.2 11 7 25.3 5.2 37.8 1.7 7-2 14.3-4.2 21.2-2.1a6 6 0 003.3.4c1.5-.4 2.2-2 2.7-3.5a90.3 90.3 0 004.5-32.3 88.8 88.8 0 01-4.5 25z"
      opacity=".1"
    />
  </svg>
)
